<script lang="ts">
  import { Router } from '@roxi/routify';
  import { addMessages, init } from 'svelte-i18n';
  import { routes } from '../.routify/routes';
  import en from './locales/en.json';
  import inLang from './locales/in.json';
  // import { getInitialLocale } from './utils/i18n';

  const initialLocale = 'en'; // getInitialLocale();
  addMessages('en', en);
  addMessages('in', inLang);
  init({ fallbackLocale: 'en', initialLocale });

  $: config = {
    // urlTransform: {
    //   // add the language prefix for the browser
    //   apply: (url: string) => {
    //     return '/' + $locale + url;
    //   },
    //   // remove the language prefix for Routify
    //   remove: (url: string) => {
    //     return url.replace('/' + $locale, '') || '/';
    //   },
    // },
  };
</script>

<Router {routes} {config} />
