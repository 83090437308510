
/**
 * @roxi/routify 2.18.12
 * File generated Fri Jan 12 2024 10:30:31 GMT+1300 (New Zealand Daylight Time)
 */

export const __version = "2.18.12"
export const __timestamp = "2024-01-11T21:30:31.407Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/collectables",
      "id": "_collectables",
      "component": () => import('../src/pages/collectables.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/content/index",
          "id": "_content_index",
          "component": () => import('../src/pages/content/index.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "path": "/content",
      "id": "_content__layout",
      "component": () => import('../src/pages/content/_layout.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/converted",
      "id": "_converted",
      "component": () => import('../src/pages/converted.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/faq",
      "id": "_faq",
      "component": () => import('../src/pages/faq.svelte').then(m => m.default)
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "path": "/legal/privacy-policy",
          "id": "_legal_privacyPolicy",
          "component": () => import('../src/pages/legal/privacy-policy.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/legal/reward/flexiroam",
              "id": "_legal_reward_flexiroam",
              "component": () => import('../src/pages/legal/reward/flexiroam.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/legal/reward/kookaburra",
              "id": "_legal_reward_kookaburra",
              "component": () => import('../src/pages/legal/reward/kookaburra.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/legal/reward/masuri",
              "id": "_legal_reward_masuri",
              "component": () => import('../src/pages/legal/reward/masuri.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/legal/reward/twenty20",
              "id": "_legal_reward_twenty20",
              "component": () => import('../src/pages/legal/reward/twenty20.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/legal/reward/vice",
              "id": "_legal_reward_vice",
              "component": () => import('../src/pages/legal/reward/vice.svelte').then(m => m.default)
            }
          ],
          "path": "/legal/reward"
        },
        {
          "isPage": true,
          "path": "/legal/terms",
          "id": "_legal_terms",
          "component": () => import('../src/pages/legal/terms.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "path": "/legal",
      "id": "_legal__layout",
      "component": () => import('../src/pages/legal/_layout.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/maintenance",
      "id": "_maintenance",
      "component": () => import('../src/pages/maintenance.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/rewards/claim",
          "id": "_rewards_claim",
          "component": () => import('../src/pages/rewards/claim.svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/rewards/index",
          "id": "_rewards_index",
          "component": () => import('../src/pages/rewards/index.svelte').then(m => m.default)
        }
      ],
      "path": "/rewards"
    },
    {
      "isPage": true,
      "path": "/rewards-backup",
      "id": "_rewardsBackup",
      "component": () => import('../src/pages/rewards-backup.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "path": "/tailor/claim",
          "id": "_tailor_claim",
          "component": () => import('../src/pages/tailor/claim.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/tailor/customise",
          "id": "_tailor_customise",
          "component": () => import('../src/pages/tailor/customise.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/tailor/pick",
          "id": "_tailor_pick",
          "component": () => import('../src/pages/tailor/pick.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/tailor/select",
          "id": "_tailor_select",
          "component": () => import('../src/pages/tailor/select.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/tailor/succeed",
          "id": "_tailor_succeed",
          "component": () => import('../src/pages/tailor/succeed.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "path": "/tailor",
      "id": "_tailor__layout",
      "component": () => import('../src/pages/tailor/_layout.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/waiting",
      "id": "_waiting",
      "component": () => import('../src/pages/waiting.svelte').then(m => m.default)
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

